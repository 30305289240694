import { useLargeFromMediaQuery } from '@madpaws/design-system';
import { Formik } from 'formik';

import {
  SFS_HOME_SEARCH_TEST_EPPO_ID,
  SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';

import { AdvancedSearchForm } from './AdvancedSearchForm/AdvancedSearchForm';
import { MobileSearchWidget } from './MobileSearchWidget';
import { searchWidgetValidationSchema, validateSearchWidget } from './utils';

import type { OnBeforeModalOpen } from './types';
import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

type Props = {
  handleFiltersSave: (searchFilters: SearchFilters) => void;
  onBeforeModalOpen?: OnBeforeModalOpen;
  searchFilters: SearchFilters;
};

export const MobileSearchWidgetWrapper = ({
  handleFiltersSave,
  searchFilters,
  onBeforeModalOpen,
}: Props): ReactElement | null => {
  const isLargeFromViewport = useLargeFromMediaQuery();
  const searchExperimentEnabled =
    useEppoStringAssignment(SFS_HOME_SEARCH_TEST_EPPO_ID) ===
    SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES.ON;

  if (isLargeFromViewport) {
    return null;
  }

  return (
    <Formik
      initialValues={searchFilters}
      onSubmit={handleFiltersSave}
      validate={validateSearchWidget}
      validateOnBlur={false}
      validationSchema={searchWidgetValidationSchema}
    >
      {searchExperimentEnabled ? (
        <AdvancedSearchForm />
      ) : (
        <MobileSearchWidget onBeforeModalOpen={onBeforeModalOpen} />
      )}
    </Formik>
  );
};
